export const ImgFilter = function ($url){
    if(/^http/.test($url)){
        return $url.replace('com/n0', 'com/img');
    }else if(/^\/.{4}\//.test($url)){
        // /2017/12/25/5a40b39bc9819.jpg
        return 'http://admin.fufentong.com/media/upload'+$url;
    }else{
        // /tmp/uploads/20181127/4410c3daa57707828e7e242aa97395fa.png
        return 'http://mapi.fufentong.com'+$url;
    }
}
