import service from '../components/http'
import qs from 'qs';
import {get} from '../components/storage'


//-----------------登录----------------
export const SignIn = (data)=>{
    return service({
        url:'supplier_login/signIn',
        data,
        method:'post'
    })
}

export const GetList = (data)=>{
    return service({
        url:'',
        data,
        method:'post'
    })
}
//-------------获取供应商分类------------
export const commParam = ()=>{
    return service({
        url:'supplier_goods/commParam',
        method:'post'
    })
}
//---------------保存商品---------------
export const saveGoods = (data)=>{
    return service({
        url:'supplier_product/saveGoods',
        data,
        method:'post'
    })
}

//---------------获取商品列表--------------

export const getGoodsList = (content)=>{
    let data = Object.assign({page:1},content);
    Object.keys(data).forEach(ite=>{if(!data[ite]){delete data[ite]}});
    return service({
        url:'supplier_goods/goodsList',
        data,
        method:'post'
    })
}

//---------------获取月对账记录--------------

export const getMonthBillRecord = (content) => {
    let data = Object.assign({
        page: 1
    }, content);
    return service({
        url: 'supplier_report/monthBillRecord',
        data,
        method: 'post'
    })
}

//--------------获取供应商商品详情------------

export const getGoodsDetail = (data)=>{
    return service({
        url:'supplier_product/saveTpl',
        data,
        method:'post'
    })
}

/**-------------供应商订单列表--------------
 *  query :
 *      token,
 *      ordered,0:未发货，1：已发货
 *      page:翻页
 *      order_sn：订单号查询 
 *      s_time:开始时间
 *      e_time:结束时间 
*/

export const getOrderList = (content)=>{
    let params = Object.assign({page:1},content);
    return service({
        url:'supplier_index/getIndexInfo',
        params,
        method:'get'
    })
}

//---------------获取上月对账明细--------------

export const getMonthBillLast = (content) => {
    let data = Object.assign({
        page: 1
    }, content);
    return service({
        url: 'supplier_report/monthBillDetail',
        data,
        method: 'post'
    })
}

//---------------获取上月退款明细--------------

export const getMonthRefundLast = (content) => {
    let data = Object.assign({
        page: 1
    }, content);
    return service({
        url: 'supplier_report/monthRefundDetail',
        data,
        method: 'post'
    })
}

//---------------导出明细--------------

export const getExport = (url,data ) => {
    let baseData = {
        token: get('fft-supplier') ? get('fft-supplier').token : null
    };
    let content = Object.assign(baseData, data);
    let downloadUrl = 'https://houtai.fufentong.com/supplier/supplier_report/' + url +'?' + qs.stringify(content) 
    let  downloadLink = window.document.createElement('a')
    downloadLink.setAttribute('href', downloadUrl)
    downloadLink.click()
}

/**
 * ---------------供应商发货接口-------------
 * url :'supplier_index/editOrder
 * order_id,order_sn  
 * express_id,express_nums,express_name,express_code
 */
export const shipment = (data)=>{
    return service({
        url:'supplier_index/editOrder',
        data,
        method:'post'
    })
}

//--------------供应商信息添加接口------------

export const saveSupplierInfo = (data)=>{
    return service({
        url:'supplier_add_info/saveSupplierInfo',
        data,
        method:'post'
    })
}

//--------------获取供应商信息接口------------

export const getSupplierInfo = (data)=>{
    return service({
        url:'supplier_add_info/getSupplierInfo',
        data,
        method:'post'
    })
}

//--------------新建物流信息接口------------

export const addExpress = (data)=>{
    return service({
        url:'supplier_index/addExpress',
        data,
        method:'post'
    })
}

//--------------批量上传物流信息接口------------

export const expressInfo = (data)=>{
    return service({
        url:'supplier_index/batchSendOutGoods',
        // https://fft-oss.matefix.cn/oss/file/uploadOne?project=fft
        data,
        method:'post'
    })
}
