import React ,{ Component }from "react";
import { Input,Collapse,Form,Button,Icon } from 'antd';
import './layout.less'
import {Upload} from '../components/Upload'
import { ImgFilter } from '../utils'
const { Panel } = Collapse;

export const BasicForm = Form.create('BasicForm')(class extends Component {
    constructor(props){
        super(props);
    }
    state = {
      recent: 'today',
      categoryList: [],
      brandList: [],
    }
  
    formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  
    formGrid = {
      xs: 24,
      sm: 6,
    }
    

    componentDidMount(){
    }
  
    onSearch = () => {
      const { validateFields } = this.props.form
      validateFields((err, values) => {
        if (err) {
          return
        }
        console.log(values)
      })
    }
  
    render() {
      const { getFieldDecorator } = this.props.form
      const { supplierInfo } = this.props
      return (
        <Form {...this.formItemLayout}>
                    <Form.Item label="公司全程">
                        {getFieldDecorator('company_full_name',{initialValue: supplierInfo.company_full_name})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="对接人">
                        {getFieldDecorator('broker',{initialValue: supplierInfo.broker})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="职称">
                        {getFieldDecorator('job',{initialValue: supplierInfo.job})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="对接人手机">
                        {getFieldDecorator('mobile',{initialValue: supplierInfo.mobile})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="公司地址">
                        {getFieldDecorator('company_address',{initialValue: supplierInfo.company_address})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="退货地址">
                        {getFieldDecorator('return_address',{initialValue: supplierInfo.return_address})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="收件人">
                        {getFieldDecorator('consignee',{initialValue: supplierInfo.consignee})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="收件人手机">
                        {getFieldDecorator('receive_mobile',{initialValue: supplierInfo.receive_mobile})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="售后电话">
                        {getFieldDecorator('sale_mobile',{initialValue: supplierInfo.sale_mobile})
                        (<Input/>
                        )}
                    </Form.Item>
      </Form>
      )
    }
  })
export const InvoiceForm = Form.create('InvoiceForm')(class extends Component {
    constructor(props){
        super(props);
    }
    state = {
      recent: 'today',
      categoryList: [],
      brandList: [],
    }
  
    formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  
    formGrid = {
      xs: 24,
      sm: 6,
    }
  
    onSearch = () => {
      const { validateFields } = this.props.form
      validateFields((err, values) => {
        if (err) {
          return
        }
        console.log(values)
      })
    }
  
    render() {
      const { getFieldDecorator } = this.props.form
      const { supplierInfo } = this.props
      return (
        <Form {...this.formItemLayout}>
                    <Form.Item label="发票抬头">
                        {getFieldDecorator('invoice_head',{initialValue: supplierInfo.invoice_info.invoice_head})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="税务登记号">
                        {getFieldDecorator('tax_num',{initialValue: supplierInfo.invoice_info.tax_num})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="地址">
                        {getFieldDecorator('invoice_address',{initialValue: supplierInfo.invoice_info.invoice_address})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="电话">
                        {getFieldDecorator('phone',{initialValue: supplierInfo.invoice_info.phone})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="开户行">
                        {getFieldDecorator('bank_name',{initialValue: supplierInfo.invoice_info.bank_name})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="开户行账号">
                        {getFieldDecorator('bank_account',{initialValue: supplierInfo.invoice_info.bank_account})
                        (<Input/>
                        )}
                    </Form.Item>
                    {/* <Form.Item label="经营许可证">
                        {getFieldDecorator('business_license',{initialValue: supplierInfo.business_license})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="开户许可证">
                        {getFieldDecorator('permit',{initialValue: supplierInfo.permit})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="开票文件存档">
                        {getFieldDecorator('invoice_file',{initialValue: supplierInfo.invoice_file})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="食品许可证">
                        {getFieldDecorator('food_permit',{initialValue: supplierInfo.food_permit})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="商标证">
                        {getFieldDecorator('brand_card',{initialValue: supplierInfo.brand_card})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="授权书">
                        {getFieldDecorator('empower',{initialValue: supplierInfo.empower})
                        (<Input/>
                        )}
                    </Form.Item>
                    <Form.Item label="质量报告">
                        {getFieldDecorator('quality_report',{initialValue: supplierInfo.quality_report})
                        (<Input/>
                        )}
                    </Form.Item> */}
      </Form>
      )
    }
  })
export const AnnexForm = Form.create('AnnexForm')(class extends Component {
    constructor(props){
        super(props);
    }
    state = {
      recent: 'today',
      categoryList: [],
      brandList: [],
      business_license: '',
      uploadObj: this.props.supplierInfo.annex
    }
  
    formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  
    formGrid = {
      xs: 24,
      sm: 6,
    }

    componentDidMount(){
      const {uploadObj} = this.state
      console.log('uploadObj',uploadObj)
      for(let key of Object.keys(uploadObj)){
        // item = ImgFilter(item)
        uploadObj[key] = ImgFilter(uploadObj[key])
      }
      this.setState({
        uploadObj:uploadObj
      })
    }

    onImportBrand = (name) => {
      console.log(name)
      const { setFieldsValue } = this.props.form
      const that = this
      const {uploadObj} = that.state
        const input = document.createElement('input');
        input.type = 'file';
        input.id = name
        // eslint-disable-next-line func-names
        input.onchange = function() {
            Upload(input.files[0])
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
              uploadObj[name] = ImgFilter(res.data)
              that.setState({
                uploadObj:uploadObj
              })
              setFieldsValue({['business_license']:res.data})
            }
          })
        };
        input.click()
      }
      
    onSearch = () => {
      const { validateFields } = this.props.form
      validateFields((err, values) => {
        if (err) {
          return
        }
        console.log(values)
      })
    }

    render() {
      const { getFieldDecorator } = this.props.form
      const { supplierInfo } = this.props
      const {uploadObj} = this.state
      return (
        <Form {...this.formItemLayout}>
                    <Form.Item label="经营许可证" extra={uploadObj.business_license}>
                      {getFieldDecorator('business_license',{initialValue: supplierInfo.annex.business_license})(
                          <Button onClick={this.onImportBrand.bind(this,'business_license')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
                    <Form.Item label="开户许可证" extra={uploadObj.permit}>
                      {getFieldDecorator('permit',{initialValue: supplierInfo.annex.permit})(
                          <Button onClick={this.onImportBrand.bind(this,'permit')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
                    <Form.Item label="开票文件存档" extra={uploadObj.invoice_file}>
                      {getFieldDecorator('invoice_file',{initialValue: supplierInfo.annex.invoice_file})(
                          <Button onClick={this.onImportBrand.bind(this,'invoice_file')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
                    <Form.Item label="食品许可证" extra={uploadObj.food_permit}>
                      {getFieldDecorator('food_permit',{initialValue: supplierInfo.annex.food_permit})(
                          <Button onClick={this.onImportBrand.bind(this,'food_permit')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
                    <Form.Item label="商标证" extra={uploadObj.brand_card}>
                      {getFieldDecorator('brand_card',{initialValue: supplierInfo.annex.brand_card})(
                          <Button onClick={this.onImportBrand.bind(this,'brand_card')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
                    <Form.Item label="授权书" extra={uploadObj.empower}>
                      {getFieldDecorator('empower',{initialValue: supplierInfo.annex.empower})(
                          <Button onClick={this.onImportBrand.bind(this,'empower')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
                    <Form.Item label="质量报告" extra={uploadObj.quality_report}>
                      {getFieldDecorator('quality_report',{initialValue: supplierInfo.annex.quality_report})(
                          <Button onClick={this.onImportBrand.bind(this,'quality_report')}>
                            <Icon type="upload" /> 上传
                          </Button>
                      )}
                    </Form.Item>
      </Form>
      )
    }
  })