import React, { Component } from 'react';
import { Layouts } from "./layout/layout";
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { LoginContext, LoginStatus } from './config/login.context'
import './App.css';

moment.locale('zh-cn');

class App extends Component {
  constructor(props){
    super(props);
    this.toggleLogin = ()=>{
      this.setState(state=>({
          isLogin:!this.state.isLogin
      }))
    }
    this.state = {
      isLogin:LoginStatus.isLogin,
      toggleLogin:this.toggleLogin
    }
  }
  
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <LoginContext.Provider value={this.state}>
          <Layouts></Layouts>
        </LoginContext.Provider>
      </LocaleProvider>
    );
  }
}
// var nodes = {
//   tagName:'ul',
//   class:['menu-list'],
//   child:[
//       {
//           tagName:'li',
//           class:['menu-ITEM'],
//           innerText:'未开发功能！'
//       }
//   ]
// };

// var writer = (node,pnodes)=>{
//   var vnode = Object.assign(
//   {
//       tagName:'p',
//       innerText:'',
//       attrs:{},
//       class:[],
//       id:[],
//       child:[]
//   }
//   ,node)
//   var tag = document.createElement(vnode.tagName);
//   tag.className = vnode.class.join(' ');
//   tag.id = vnode.id.join(' ');
//   tag.innerHTML = vnode.innerText;
//   for(var i in vnode.attrs){
//       tag.setAttribute(i,vnode.attrs[i])
//   };
//   pnodes.appendChild(tag);
//   vnode.child.forEach(function(item,index){
//     writer(item,tag)
//   })
// };
// window.oncontextmenu = function(e){
//   var menu = document.querySelector('#menu');
//   if(e.button ===2){
//       e.preventDefault();
//       console.log(e.pageX,e.pageY);
//       if(!menu){
//           var div = document.createElement('div');
//           div.style.display = 'block';
//           div.id = 'menu';
//           div.style.position = 'fixed';
//           div.style.top  = e.pageY+'px';
//           div.style.left = e.pageX+'px'; 
//           writer(nodes,div);
//           document.body.appendChild(div);
//       }else{
//           menu.style.display = 'block';
//           menu.style.top  = e.pageY+'px';
//           menu.style.left = e.pageX+'px'; 
//           menu.style.zIndex='9999';
//       }
//   }
// }
// window.onmouseup = function(e){
//   var menu = document.querySelector('#menu');
//   if(e.button===0){
//       menu.style.display = 'none';
//   }
// }
export default App;