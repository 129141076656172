import AsyncCompnent from "../components/AsyncComponets";

const PageHome = AsyncCompnent(() => import("../pages/Home/Index"));
const OrderHome = AsyncCompnent(() => import("../pages/Order/Index"));
const Nosend = AsyncCompnent(() => import("../pages/NoOrder/Index"));
const BillMonth = AsyncCompnent(() => import("../pages/BillMonth/Index"));
const BillLast = AsyncCompnent(() => import("../pages/BillLast/Index"));
const ReturnBill = AsyncCompnent(() => import("../pages/ReturnBill/Index"));
const Goods = AsyncCompnent(() => import("../pages/Goods/index"));
const AddGoods = AsyncCompnent(() => import("../pages/AddGoods/index"));

// const PageList = AsyncCompnent(() => import("../pages/PageList.jsx"));
// const PageMarker = AsyncCompnent(() => import("../pages/PageMarker.jsx"));
// const PageSetting = AsyncCompnent(() => import("../pages/PageSetting.jsx"));

const ROUTES = [
    {
        key: 'Goods',
        link: '/goods',
        iconType: 'home',
        text: '商品管理',
        component: Goods
    },
    {
        key: 'Home',
        link: '/home',
        iconType: 'home',
        text: '首页',
        component: PageHome
    },
    {
        key: 'Order',
        link: '/order',
        iconType: 'home',
        text: '已发货订单',
        component: OrderHome
    },
    {
        key: 'NoSendOrder',
        link: '/nosendorder',
        iconType: 'home',
        text: '未发货订单',
        component: Nosend
    },
    {
        key: 'NoSendRecent',
        link: '/nosendrecent',
        iconType: 'home',
        text: '48小时未发货订单',
        component: Nosend
    },
    {
        key: 'BillMonth',
        link: '/billmonth',
        iconType: 'home',
        text: '月对账记录',
        component: BillMonth
    },
    {
        key: 'BillLast',
        link: '/billlast',
        iconType: 'home',
        text: '上月对账明细',
        component: BillLast
    },
    {
        key: 'ReturnBill',
        link: '/returnbill',
        iconType: 'home',
        text: '上月退款明细',
        component: ReturnBill
    },
    
    {
        key: 'AddGoods',
        link: '/addgoods',
        iconType: 'home',
        text: '添加商品',
        component: AddGoods
    },
];

export { ROUTES };