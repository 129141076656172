
//---------------上传主图----------------

// export const Upload  = (file)=>{
//     var formData=new FormData();
//     formData.append('image',file); 
//     return service({
//         url:'file/uploads.html',
//         formData,
//         method:'post'
//     })
// }
import axios from "axios";
import qs from 'qs'
import { message } from 'antd'; 

const Uploader = axios.create({
    baseURL:'https://mapi.fufentong.com/master/',
    timeout:5000
})
Uploader.interceptors.response.use(
    res=>{
        if(res.data.status===200){
            return res.data
        }else{
            message.warn(res.data.msg);
            return res.data
        }
    },
    err=>{
        if(err){
            let text = '网络异常，请重试';
            message.error(text);
        }
        return Promise.reject(err)
    }
)


export const Upload =  (file, query)=>{
    let  formData = new FormData();
    formData.append('image',file); 
    const params = qs.stringify({
        project:'fft',
        ...query
    })
    
    return Uploader({
        // url:'api/file/uploads',
        url:'https://fft-oss.matefix.cn/oss/file/uploadOne?'+params,
        data:formData,
        method:'post'
    })
}