import axios from "axios";
// import promise from 'es6-promise'
import qs from 'qs';
import {message} from 'antd'; 
import {get} from './storage'
// promise.polyfill()
// import { createBrowserHistory } from 'history'; // 是hash路由 history路由 自己根据需求来定

// const history = createBrowserHistory();
let BASEURL = 'https://houtai.fufentong.com/supplier/';
let dev = window.location.hostname.split('.')[0];
if (dev === 'localhost' || dev === '192' || dev === 'test'|| dev === '172') {
    // BASEURL = `http://test.houtai.fufentong.com/supplier/`
}
const service  = axios.create({
    baseURL:BASEURL,
    timeout:0
})

service.interceptors.request.use(conf=>{
    let baseData = {
        token:get('fft-supplier')?get('fft-supplier').token:null
    }
    conf.method === 'get'
        ? conf.params = Object.assign(baseData,{...conf.params})
        : conf.data = qs.stringify(Object.assign(baseData,{...conf.data}));
    conf.headers['Content-Type'] = 'application/x-www-form-urlencoded';
 
    return conf;
},err=>{
    message.error(err);
    Promise.reject(err)
})

service.interceptors.response.use(
    res=>{
        if(res.data.status===200){
            return res.data
        }else if(res.data.status===1001){
            message.warn(res.data.msg);
            return res.data
        }
        else{
            message.warn(res.data.msg);
            return res.data
        }
    },
    err=>{
        if(err){
            let text = '网络异常，请重试';
            message.error(text);
        }
        return Promise.reject(err)
    }
)
export default service;