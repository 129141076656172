import React from 'react'
import {get} from '../components/storage'

export const LoginStatus  = {
    isLogin:get('fft-supplier')?true:false
}
export const LoginContext = React.createContext(
    {
        isLogin:LoginStatus.isLogin,
        toggleLogin:()=>{}
    }
);