import React from "react";
import { Menu, Icon } from "antd";
import { Link } from 'react-router-dom';
import { ROUTES } from "../config/routes.config";
import _ from "lodash";

const routes = _.clone(ROUTES);
class Menus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: '',
            arr:[]
        };
    }
    componentWillMount() {
        let pathname = window.location.pathname;
        let index = _.findIndex(routes, route => route.link === pathname );
        index = index===-1?0:index;
        this.setState({activeKey: routes[index]['key']});
        this.props.updateActive(routes[index]['key']);
    }

    handleClick = (e) => {
        this.setState({activeKey: e.key});
        this.props.updateActive(e.key);
    };

    render() {

        return (
            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[this.state.activeKey]}
                style={{ lineHeight: '64px' }}
                onClick={this.handleClick}
            >

               {
                   /**
                 <Menu.Item key='Home'>
                    <Link to='/home'><Icon type='home'/><span>首页</span></Link>
                </Menu.Item>
                    */
               }
                <div className='menu-item-title'>商品</div>
                <Menu.Item key='Goods'>
                    <Link to='/goods'><Icon type='build'/><span>商品列表</span></Link>
                </Menu.Item>
                <div className='menu-item-title'>订单</div>
                <Menu.Item key='Order'>
                    <Link to='/order'><Icon type="solution" /><span>已发货订单</span></Link>
                </Menu.Item>
                <Menu.Item key='NoSendOrder'>
                    <Link to='/nosendorder'><Icon type="exception" /><span>未发货订单</span></Link>
                </Menu.Item>
                <Menu.Item key='NoSendRecent'>
                    <Link to='/nosendrecent?recent=true'><Icon type="exception" /><span>48小时未发货订单</span></Link>
                </Menu.Item>
                <div className='menu-item-title'>报表</div>
                <Menu.Item key='BillMonth'>
                    <Link to='/billmonth'><Icon type="account-book" /><span>月对账记录</span></Link>
                </Menu.Item>
                <Menu.Item key='BillLast'>
                    <Link to='/billlast'><Icon type="reconciliation" /><span>上月对账明细</span></Link>
                </Menu.Item>
                <Menu.Item key='ReturnBill'>
                    <Link to='/returnbill'><Icon type="file-search" /><span>上月退款明细</span></Link>
                </Menu.Item>
            </Menu>
        );
    }
}


export default Menus;