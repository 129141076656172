import React from "react";
import {Layout,Button,Input,message} from 'antd'
import {add} from '../components/storage'
import {SignIn} from '../config/api.config'
import './Login.less'

class Login extends React.Component {
    state = {
        loading:false,
        username:'',
        password:''
    }

    async login(){
        this.setState({
            loading:true
        })
        let {username,password} = this.state;
        if(!username){
            message.error('请输入用户名')
            this.setState({
                loading:false
            })
            return 
        }else if(!password){
            message.error('请输入密码');
            this.setState({
                loading:false
            })
            return 

        }
        let res = await SignIn({username,password});
        if(res.status === 200){
            add('fft-supplier',res.data)
            setTimeout(()=>{
                this.setState({
                    loading:false
                })
                // message.error('登录失败！')
                this.props.onLogin()
            },800)
            this.setState({
                loading:false
            })
        }else{
            this.setState({
                loading:false
            })
        }
       
        
    }
    handleChange (tar,e){
        this.setState({
            [tar]:e.target.value
        })
    }
    render() {
        const { Header, Content ,Footer} = Layout;
        const {username,password} = this.state
        return(
            <Layout 
            className='login'
            style={{ minHeight: '100vh' }}>
                <Header className='login-header'>
                    <div className='container'>
                        <span className='title'>福分通供应商管理后台</span>
                    </div>
                </Header>
                <Content className='login-content'>
                    <div 
                    className='container'
                    style={{textAlign:'center'}}>
                        <div
                         className='login-form'
                        >

                        <h1 className='form-title'>供应商管理后台</h1>
                        <Input placeholder='请输入供应商账户' 
                        size='large' 
                        allowClear
                        value={username}
                        onChange={this.handleChange.bind(this,'username')}
                        ></Input>
                        <div className='form-placeholder'></div>
                        <Input placeholder='请输入账户密码' 
                        allowClear                        
                        size='large' 
                        value={password} 
                        type='password'
                        onChange={this.handleChange.bind(this,'password')}
                        ></Input>
                        <div className='form-placeholder'></div>
                        <Button
                         onClick={this.login.bind(this)}
                         type='primary' 
                         loading={this.state.loading}
                         block
                         size='large'>点击登录</Button>
                        </div>
                    </div>
                </Content>
                <Footer
                    style={{textAlign:'center'}}
                >
                    福分通供应商订单管理后台-欧菲斯办公伙伴                
                </Footer>
            </Layout>
        );
    }

}
Login.defaultProps = {
    isLogin:false
}

export default Login;