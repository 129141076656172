import React ,{ Component }from "react";
import { BrowserRouter as Router, Route,Redirect,Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'; // 是hash路由 history路由 自己根据需求来定
import { Layout,Icon, Modal,Input,Collapse,Form,Button } from 'antd';
import {saveSupplierInfo,getSupplierInfo} from '../config/api.config'
import Menus from "./menus";
import Login from "./Login"
import {get,remove} from '../components/storage'
import {Upload} from '../components/Upload'
import { ROUTES } from "../config/routes.config";
import _ from "lodash";
import {LoginContext} from '../config/login.context'
import './layout.less'
import {BasicForm,InvoiceForm,AnnexForm} from './SuplierForm'
import {productImgFilter} from '../pages/AddGoods/components/Filter'
const history = createBrowserHistory();
const routes = _.clone(ROUTES);
const { Panel } = Collapse;

class Layouts extends Component {
    static contextType = LoginContext
    constructor(props) {
        super(props);
        this.toggleLogin = ()=>{
            this.setState(state=>({
                isLogin:state.isLogin === false?true:false
            }))
        }
        this.image = React.createRef();
        this.state = {
            current: "Home",
            path:"/home",
            user:get('fft-supplier')?get('fft-supplier').nick_name:'',
            visible: false,
            modal_images:[
            ],
            supplierInfo: {}
        }
    }

    async componentDidMount(){
        let res = await getSupplierInfo()
        if(res.status === 1001){
            this.context.toggleLogin()
            return 
        }
        
        if (res.status === 200){
            let supplierInfo = res.data[0]
            this.setState({
                supplierInfo: supplierInfo
            })
        }
    }

    showModal = () => {
        this.setState({
        visible: true,
        });
    };

    hideModal = () => {
        this.setState({
        visible: false,
        });
    };
    componentWillMount(){
        
    }
    updateActive = (key) => {
        let index = _.findIndex(routes, route => route.key === key);
        this.setState({
            current: routes[index]['text'],
            path:routes[index]['link']
        });
    };

    NoMatch({ location }) {
        return (
          <div>
            <h3>
              No match for <code>{location.pathname}</code>
            </h3>
          </div>
        );
    }
    changeLoginStatus = ()=>{
        this.context.toggleLogin()
    }
    logout = ()=>{
        remove('fft-supplier');
        remove('supplier_param');
        this.context.toggleLogin()
    }
    transkey = ()=>{
        return window.location.pathname
    }
    async submitModal(){
        let basicInfo = {}
        let invoicInfo = {}
        let annexInfo = {}
        this.formBasicRef.props.form.validateFields((err, values) => {
            if (err) {
              return
            }
            basicInfo = values
          })
        this.formInvoiceRef.props.form.validateFields((err, values) => {
            if (err) {
              return
            }
            invoicInfo = values
          })
        this.formAnnexRef.props.form.validateFields((err, values) => {
            if (err) {
              return
            }
            annexInfo = values
          })
        basicInfo.invoice_info = JSON.stringify(invoicInfo)
        basicInfo.annex = JSON.stringify(annexInfo)
        console.log(basicInfo)
        let res = await saveSupplierInfo(basicInfo)
        alert(res.msg)
        console.log(res)
    }
    //上传图片
    async imageInputmodal(){
        let file = this.image.current.files[0];
        let modal_images = this.state.modal_images;
        const res = await Upload(file);
        modal_images.push(res.data);
        this.setState({
            modal_images:modal_images
        })
    }
    delProductImgmodal(k){
        let {modal_images} = this.state
        modal_images.splice(k,1);
        this.setState({
            modal_images:modal_images
        })
    }
    render(){
        const { Header, Content,Sider } = Layout;
        const {modal_images,supplierInfo} = this.state
        return(
            <LoginContext.Consumer>
                {
                    ({isLogin,toggleLogin})=>(
                        isLogin?
                    <Router history={history}>
                        <Layout style={{ minHeight: '100vh' }}> 
                            <Sider className='aside'
                            
                            >
                                <div className="logo" >
                                福分通-供应商后台
                                </div>
                                <Menus
                                    updateActive={this.updateActive}
                                />
                            </Sider>
                            <Layout className="layout" style={{ minWidth: '996px',height:'100vh' }}>
                                <Header className='lay-header'>
                                    <div className='float-right logout' onClick={this.logout}>退出</div>
                                    <div className='float-right user-info'>
                                        <span title={'fufentongyf@163.com'} onClick={this.showModal}>{this.state.user}</span>
                                    </div>
                                    {/**<div className='float-right operation'><span>{this.state.user}</span></div> */}
                                    <div className='float-left'>
                                        <span>{this.state.current}</span>
                                    </div>
                                </Header>
                                <Content style={{ padding: '20px 20px' }}>
                                    <div style={{ background: '#fff', minHeight: '100%',height:'100%','display':'flex' }}>
                                            <Switch >
                                                <Route exact path='/' render={() => (<Redirect to="/goods"/>)}/>
                                                    {
                                                        routes.map((route,key) =>
                                                            (
                                                                <Route exact
                                                                key={route.key} 
                                                                path={route.link}
                                                                component={route.component}
                                                                />
                                                            )
                                                        )
                                                    }
                                                <Route component={this.NoMatch} />
                                            </Switch>
                                    </div>
                                </Content>
                            </Layout>
                        </Layout>
                        <Modal
                            title="编辑供应商信息"
                            visible={this.state.visible}
                            onOk={this.submitModal.bind(this)}
                            onCancel={this.hideModal}
                            okText="保存"
                            cancelText="取消"
                            >
                                <Collapse defaultActiveKey={['1']} accordion>
                                    <Panel header="基本信息" key="1" className="model-panel">
                                        <BasicForm wrappedComponentRef={(inst) => this.formBasicRef = inst} supplierInfo={supplierInfo}></BasicForm>
                                    </Panel>
                                    <Panel header="开票信息" key="2" className="model-panel" forceRender>
                                        <InvoiceForm wrappedComponentRef={(inst) => this.formInvoiceRef = inst} supplierInfo={supplierInfo}></InvoiceForm>
                                    </Panel>
                                    <Panel header="附件" key="3" className="model-panel" forceRender>
                                        <AnnexForm wrappedComponentRef={(inst) => this.formAnnexRef = inst} supplierInfo={supplierInfo}></AnnexForm>
                                    </Panel>
                            </Collapse>
                        </Modal>
                    </Router>:
                    <Login isLogin={isLogin} onLogin={this.changeLoginStatus}></Login>
                    )
                }    
            </LoginContext.Consumer>
        )
    }
}
export {Layouts}
